import { createApp } from 'vue'
import App from '@/App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/sass/index.scss'
import './registerServiceWorker'
import RectItem from '@/components/RectItem.vue';
import Heading from '@/components/HeadingItem.vue';
import { ajaxGet, jsonPost } from './util'
import router from './router'
import store from './store'
const app = createApp(App)
app.use(ElementPlus)

app.component("RectItem", RectItem)
app.component("HeadingItem", Heading)
app.config.globalProperties.$ajaxGet = ajaxGet

app.config.globalProperties.$jsonPost = jsonPost



app.use(store).use(router).mount('#app')
