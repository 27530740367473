import axios from "axios"
import qs from 'qs'
const ajax = axios.create({
  baseURL: '/api/',
  timeout: 1000,
  headers: {'X-Custom-Header': 'foobar'}
});
ajax.interceptors.request.use(
  function(config) {
    // 文件下载时间放大
    if (config.responseType === 'blob') {
      config.timeout = 60 * 60 * 1000
    }
    //每次发送请求之前检测都vuex存有token,那么都要放在请求头发送给服务器
    return config
  },
  function(err) {
    return Promise.reject(err)
  }
)
ajax.interceptors.response.use(function(res) {
  if (res.status === 200) {
    return res.data
  }
}, function(err) {
  console.log(err)
})
const ajaxGet = (api:any, data:any = null, headers:any = null) => ajax.get(api, {
  responseType: headers && headers.responseType,
  params: { ...data, _: new Date().getTime() },
  paramsSerializer: function(params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
})
const jsonPost = (api:any, data:any = null, headers:any = null) => ajax.post(api, {
  ...data
},  { headers: { 'Content-Type': 'application/json;charset=UTF-8'}})

export {
  ajaxGet,
  jsonPost,
  ajax
}