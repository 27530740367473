import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "rect",
    style: _normalizeStyle({'margin': _ctx.margins + 'px',
             'padding': _ctx.paddings + 'px',
             'width': _ctx.width + 'px',
             'height': _ctx.height + 'px',
             'border': _ctx.borderStyle
            })
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 4))
}