import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/BaseMenu.vue'
import User from './user'
import typeScript from './typeScript'
import other from './other'

import ui from './ui'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/about',
    name: 'BaseMenu',
    component: Home,
    index: '1',
    children: [
      {
        path: '/about',
        name: 'About',
        meta: {
          title: '首页',
          index: '1-1'
        },
        component: () => import('../views/About.vue')
      }
    ]
  },
  User,
  typeScript,
  ui,
  ...other,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
