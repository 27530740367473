interface dataType {
  path: string,
  name: string,
  component: any,
  meta: any
}
import Home from '../views/BaseMenu.vue'
const data:Array<dataType> = [
  {
    path: '/ts/enum',
    name: 'ts/enum',
    component: () => import('../views/ts/enum.vue'),
    meta: {
      title: 'enum',
      index: '3-1'
    }
  }, {
    path: '/ts/assign',
    name: 'ts/assign',
    component: () => import('../views/ts/assign.vue'),
    meta: {
      title: 'assign',
      index: '3-2'
    }
  }
]
const parent = {
  path: '/BaseMenu',
  name: 'ts',
  component: Home,
  children: data,
  index: '3'
}
export default parent