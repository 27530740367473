interface dataType {
  path: string,
  name: string,
  component: any,
  meta: any
}
import Home from '../views/BaseMenu.vue'
const data:Array<dataType> = [
  {
    path: '/demo',
    name: 'demo',
    component: () => import('../views/Demo.vue'),
    meta: {
      title: '网站',
      index: '2-1'
    }
  }
]
const parent = {
  path: '/BaseMenu',
  name: 'node',
  component: Home,
  children: data,
  index: '2'
}
export default parent