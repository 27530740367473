interface dataType {
  path: string,
  name: string,
  component: any,
  meta: any
}
import Home from '../views/BaseMenu.vue'
const data:Array<dataType> = [
  {
    path: '/ui/drag',
    name: 'ui/drag',
    component: () => import('../views/ui/drag.vue'),
    meta: {
      title: '拖拽',
      index: '4-1'
    }
  },
  {
    path: '/ui/task',
    name: 'ui/task',
    component: () => import('../views/ui/task.vue'),
    meta: {
      title: '宏任务和微任务',
      index: '4-2'
    }   
  }
]
const parent = {
  path: '/BaseMenu',
  name: 'ui',
  component: Home,
  children: data,
  index: '3'
}
export default parent