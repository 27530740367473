
import { defineComponent, h } from 'vue'
export default defineComponent({
  props: {
    level: {
      type: [String, Number],
      default: () => ''
    },
    title: {
      type: [String, Number],
      default: () => ''
    }
  },
  render() {
    return h(
      'h' + this.level,
      { attrs: {title: this.title},
        style: {margin: '20px 30px 20px'}
      },
      this.title
    )
  }
})
