interface dataType {
  path: string,
  name: string,
  component: any,
  meta: any
}
const data:Array<dataType> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/home.vue'),
    meta: {
      title: '主页',
      index: '4-1'
    }
  },
]
const parent = data
export default parent