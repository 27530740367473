
import { defineComponent, ref, reactive } from 'vue'
// import { Setting } from '@element-plus/icons'
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  // components: {
  //   Setting
  // },
  setup() {
    const router = useRouter();
    const route = useRoute();
    // console.log(route)
    let activeIndex = ref('1-1')
    const item = {
      date: '2016-05-02',
      name: 'Tom',
      address: 'No. 189, Grove St, Los Angeles'
    }
    let navList:Array<any> = router.options.routes
    const viewList:any = reactive<string[]>([])
    viewList.push({
      name: 'About',
      title: '首页',
      index: '1-1'
    })
    router.push({name: 'About'})
    // activeIndex = '2-1'
    const tableData = ref(Array(20).fill(item))
    const onChoiceTitle = (item:any) => {
      router.push({name: item.name})
      activeIndex.value = item.meta.index
      for(let arr of viewList) {
        if (arr.name === item.name) {
          return
        }
      }
      viewList.push({
        title: item.meta.title,
        name: item.name,
        index: item.meta.index
      })
    }
    const onClickNav = (item:any) => {
      activeIndex.value = item.index
      router.push({name: item.name})
    }
    return {
      tableData,
      navList,
      onChoiceTitle,
      viewList,
      activeIndex,
      onClickNav
    }
  },
})
