
// import BaseMenu from './views/BaseMenu.vue';
import { defineComponent, ref, reactive } from 'vue'
// import { Setting } from '@element-plus/icons'
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  components: {
    // Setting,
    // BaseMenu
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    console.log(route)
    const item = {
      date: '2016-05-02',
      name: 'Tom',
      address: 'No. 189, Grove St, Los Angeles'
    }
    let navList:Array<any> = router.options.routes
    const viewList:any = reactive<string[]>([])
    viewList.push({
      name: 'Home',
      title: '首页'
    })
    const tableData = ref(Array(20).fill(item))
    const onChoiceTitle = (item:any) => {
      router.push({name: item.name})
      for(let arr of viewList) {
        if (arr.name === item.name) {
          return
        } 
      }
      viewList.push({
        title: item.meta.title,
        name: item.name
      })
    }
    const onClickNav = (item:any) => {
      router.push({name: item.name})
    }
    return {
      tableData,
      navList,
      onChoiceTitle,
      viewList,
      onClickNav
    }
  },
})
