
  import { defineComponent } from 'vue'
  export default defineComponent({
    props: {
      margins: {
        type: String,
        default: () => '',
      },
      paddings: {
        type: String,
        default: () => ''
      },
      width: {
        type: String,
        default: () => ''
      },
      height: {
        type: String,
        default: () => ''
      },
      borderStyle: {
        type: String,
        default: () => '1px solid #ddd'
      }
    }
  })
